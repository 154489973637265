<template>
  <main>
    <Hero />
    <Contact />
  </main>
</template>

<script>
import Hero from "../components/Hero.vue";
import Contact from "../components/Contact.vue";

export default {
  name: "Home",
  components: {
    Hero,
    Contact,
  },
};
</script>

<style scope>

</style>
