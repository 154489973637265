<template>
  <div class="container">
    <header>
      <picture class="hero-banner-img">
        <source
          media="(min-width:1000px)"
          srcset="../assets/img/hero/hero.svg"
        />
        <img src="../assets/img/hero/me_thumbnail.webp" alt="" />
      </picture>
    </header>
    <div class="mobile-caption">
      <h2>Kieran T Brawley</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hero",
};
</script>

<style scoped>
.container {
  padding-top: 0;
}

img {
  width: 100%;
  height: auto;
}

header {
  margin: 0;
  padding: 0;
  background: #f6ae2d;
}

.mobile-caption {
  background-color: #f6ae2d;
  color: #fff;
  margin: 0;
  padding: 1rem;
}

.mobile-caption h1,
h2 {
  margin: 0;
  text-decoration: none;
}

@media (min-width: 1001px) {
  .mobile-caption {
    display: none;
  }

  header {
    background: unset;
  }
}

@media (max-width: 1200px) {
  .container {
    padding-top: 3.5rem;
  }
}

@media (max-width: 576px) {
  .container {
    padding-top: 5rem;
  }
}
</style>
