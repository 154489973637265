<template>
  <div id="app">
    <Nav />
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <Footer />
  </div>
</template>

<script>
import Nav from "./components/Nav.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

* {
  margin: 0;
  padding: 0;
}
html {
  overflow-y: scroll;
}

body {
  width: 100%;
  height: 100%;
  background: #fff;
}
#app {
  font-family: "Oswald", sans-serif;
  line-height: 1.6;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

.container {
  padding-top: 5rem;
  margin: 0 auto;
  background: #fff;
  scroll-behavior: smooth;
}

.feature {
  border: 1rem solid #f6ae2d;
}

/* 
  ========================TEXT========================
*/
h1 {
  text-decoration: underline;
  text-underline-position: below;
  text-underline-offset: 8px;
  margin-bottom: 1rem;
}

h1 {
  font-size: 3.5rem;
}
h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.8rem;
}

.banner-text {
  text-align: center;
  font-weight: 600;
  padding: 2rem;
  z-index: 10;
}

.text-center {
  text-align: center;
}

.external-ref {
  text-decoration: none;
  font-weight: 600;
  color: #2c3e50;
}

.external-ref:hover {
  color: #f6ae2d;
}

.page-title {
  text-decoration: none;
  text-align: center;
  font-size: 2.8rem;
}

/* 
  ========================ANIMATIONS========================
*/

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/*
========================DROPDOWN========================
*/
.dropdown-menu {
  flex-direction: column;
  background: #f6ae2d !important;
  position: absolute;
  padding: 1rem 1.4rem;
  top: 72px;
  border: 3px solid lightgrey;
  border-top: none;
  list-style: none;

  a {
    color: #fff !important;
  }

  li {
    margin: 1rem 0;
  }
}
/*
  ========================ICONS========================
 */
.fa-sm {
  font-size: 0.8rem;
  padding-left: 0.2rem;
}

/**
* ========================IMAGES========================
 */

img {
  width: 100%;
  height: auto;
}

.img-thumbnail {
  display: flex;
  justify-content: center;
  img {
    max-width: 250px;
  }
}

.img-rounded {
  img {
    border-radius: 50%;
  }
}

/**
========================PAGE LAYOUT========================
 */
.page-title-banner {
  padding: 2rem;
  background: #f6ae2d;
  color: #fff;

  h1,
  h2,
  h3 {
    text-decoration: none;
  }
}

/**
========================BUTTONS========================
 */
.btn {
  background: orange;
  color: #fff;
  padding: 0.2rem 1rem;
  text-align: center;
  border-radius: 15%;
  cursor: pointer;
  text-decoration: none;
  border: 2px solid #fff;
}

.hover-btn {
  position: fixed;
  bottom: 50px;
  right: 30px;
  display: none;
}
</style>
