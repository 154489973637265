<template>
  <div class="footer-container">
    <footer>
      <p>&copy;{{ year }} Kieran T Brawley</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      year: 0,
    };
  },
  methods: {},
  created() {
    this.year = new Date().getFullYear();
  },
};
</script>

<style scoped>
footer {
  display: flex;
  background: #7f7b82;
  color: #fff;
  height: 50px;
  width: 100%;
  justify-content: center;
  justify-items: center;
  padding: 1rem 0rem;
  max-width: 1500px;
  margin: 0 auto;
}
.footer-container {
  min-width: 100%;
  background: #7f7b82;
}
</style>
