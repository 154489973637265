<template>
  <div class="container">
    <p>For more information about my experience, and ongoing projects:</p>
    <div class="contacts">
      <div class="contact-item">
        <a href="https://github.com/ktbrawley" class="tooltip">
          <i class="fab fa-3x fa-github"></i>
          <span class="tooltiptext">Github</span>
        </a>
      </div>
      <div class="contact-item">
        <a href="https://www.linkedin.com/in/kierantbrawley" class="tooltip">
          <i class="fab fa-3x fa-linkedin"></i>
          <span class="tooltiptext">LinkedIn</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact',
};
</script>

<style scoped>
.container {
  text-align: center;
  padding: 1.5rem 0;
}
.contacts {
  padding: 2rem 0;
  display: flex;
  justify-content: center;
}

.contact-item {
  margin: 0 2rem;
  padding: 0;
}
a {
  text-decoration: none;
}
.fab {
  cursor: pointer;
  color: #f6ae2d;
}

.fab:hover {
  color: #e6e7e1;
}

.tooltip {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.tooltip .tooltiptext {
  color: #f6ae2d;
  font-size: 1.8rem;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
