<template>
  <div class="container">
    <nav class="navbar">
      <ul>
        <a
          ><li class="nav-banner">
            <router-link to="/">Home</router-link>
          </li></a
        >
        <li>
          <a
            class="dropdown-btn"
            @mouseenter="toggleProjectMenu('open')"
            @click="toggleProjectMenu('open')"
            ref="dropdown-btn"
          >
            Projects <i class="fas fa-sm fa-chevron-down"></i>
            <ul
              class="dropdown-menu"
              @mouseleave="toggleProjectMenu('close')"
              @readystatechange="toggleProjectMenu('close')"
              ref="proj-dropdown"
              v-closable="{
                exclude: ['proj-dropdown', 'dropdown-btn'],
                handler: 'onClose',
              }"
            >
              <li>
                <router-link to="/projects/mysteryboxpy"
                  >Mystery Box (Python)</router-link
                >
              </li>
            </ul>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import Vue from 'vue';

let handleOutsideClick;

Vue.directive('closable', {
  bind(el, binding, vnode) {
    // Here's the click/touchstart handler
    // (it is registered below)
    handleOutsideClick = (e) => {
      e.stopPropagation();
      // Get the handler method name and the exclude array
      // from the object used in v-closable
      const { handler, exclude } = binding.value;

      // This variable indicates if the clicked element is excluded
      let clickedOnExcludedEl = false;
      exclude.forEach((refName) => {
        // We only run this code if we haven't detected
        // any excluded element yet
        if (!clickedOnExcludedEl) {
          // Get the element using the reference name
          const excludedEl = vnode.context.$refs[refName];
          // See if this excluded element
          // is the same element the user just clicked on
          if (excludedEl !== undefined) {
            clickedOnExcludedEl = excludedEl.contains(e.target);
          }
        }
      });

      // We check to see if the clicked element is not
      // the dialog element and not excluded
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        // If the clicked element is outside the dialog
        // and not the button, then call the outside-click handler
        // from the same component this directive is used in
        vnode.context[handler]();
      }
    };
    // Register click/touchstart event listeners on the whole page
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);
  },

  unbind() {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
    document.removeEventListener('click', handleOutsideClick);
    document.removeEventListener('touchstart', handleOutsideClick);
  },
});

export default {
  name: 'Nav',
  methods: {
    toggleProjectMenu(override = 'default') {
      var list = document.getElementsByClassName('dropdown-menu')[0];

      if (override == 'open') {
        list.style.display = 'flex';
      } else if (override == 'close') {
        list.style.display = 'none';
      }
    },
    onClose() {
      this.toggleProjectMenu('close');
    },
  },
  watch: {
    $route() {
      this.toggleProjectMenu('close');
      // Set scroll position back to top of page
      setTimeout(() => {
        document.body.scrollTop = 0; //  Safari
        document.documentElement.scrollTop = 0; //  Chrome, Firefox, IE and Opera
      }, 200);
    },
  },
};
</script>

<style scoped>
.container {
  background: #f6ae2d;
  color: #fff;
  border-bottom: 3px solid lightgrey;
  padding: 1rem 0rem;
  position: fixed;
  min-width: 100%;
  z-index: 100;
}

.navbar {
  max-width: 1500px;
  margin: 0 auto;
}

.navbar > ul {
  display: flex;
  list-style: none;
}

.navbar a {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  margin: 0 2rem;
}

.navbar a:hover {
  color: #e6e7e1;
}

.nav-banner {
  display: flex;
}

.nav-banner img {
  height: 50px;
  width: 50px;
  margin-right: 1rem;
}

routerlink:hover {
  color: #e6e7e1;
}

.router-link-exact-active {
  text-decoration: underline !important;
  text-underline-position: under !important;
}

.navbar li {
  font-weight: 600;
}

.dropdown-menu {
  display: none;
}

.dropdown-menu > li:hover {
  color: #e6e7e1;
}


@media (max-width: 1000px) {
  .navbar > ul {
    justify-content: center;
  }
}
</style>
